body {
  overflow: hidden;
  margin: 0; /* Optionally, remove default margins */
}

.base-map {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
  z-index: 1;
}

.map-marker {
  z-index: 10;
  color: black;
  font-size: 15px;
}

.route-start-marker {
  z-index: 10;
  color: white;
  background-color: black;
  font-size: 15px;
  width: 18px !important;
  height: 18px !important;
  margin-left: -9px !important;
  margin-top: -9px !important;
  padding: 0px !important;
  border-radius: 50%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  box-shadow: 0px 0px 0px 5px rgba(132, 132, 132, 0.554);
}

.search-box {
  z-index: 10;
  height: 40px;
  width: 320px;
  display: flex;
  position: absolute;
  padding: 15px;
  padding-right: 50px;
  border: 2px solid black;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  box-shadow: -3px 3px 4px rgba(135, 135, 135, 0.314);
}

.search-box-start {
  top: 30px;
  left: 50px;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .search-box-start {
    top: 15px;
  }
}


.search-box-start:disabled {
  opacity: 0.75;
  background-color: rgba(167, 167, 167, 0.647);
}

.search-box-end {
  display: none;
}

.search-box-submit-button {
  position: absolute;
  top: 42.5px;
  left: 345px;
  z-index: 10;
  height: 15px;
  display: none;
}

@media (max-width: 768px) {
  .search-box-submit-button {
    top: 27px;
  }
}

.search-box-submit-button.active {
  display: block;
}

.search-box-submit-button.directions-active {
  position: absolute;
  top: 37px;
  left: 323px;
  z-index: 10;
  height: 25px;
  padding: 5px;
  background-color: white;
  border-radius: 0px 12px 12px 0px;
}

.search-box-directions-arrow {
  position: absolute;
  top: 42px;
  left: 323px;
  z-index: 10;
  height: 17px;
  display: none;
}

@media (max-width: 768px) {
  .search-box-directions-arrow {
    top: 27px;
  }
}


.search-box-directions-arrow.inactive {
  display: none !important;
}

.search-box-clear-directions-button.directions-active {
  display: block;
  position: absolute;
  top: 37px;
  left: 343px;
  z-index: 10;
  height: 25px;
  background-color: white;
  padding: 5px;
}

.search-box-clear-directions-button {
  display: none;
}

.search-box.directions-active {
  z-index: 10;
  height: 20px;
  width: 320px;
  display: flex;
  position: absolute;
  padding: 15px;
  padding-right: 50px;
  border: 2px solid black;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  box-shadow: -3px 3px 4px rgba(135, 135, 135, 0.314);
}

.search-box-start.directions-active {
  top: 20px;
  left: 50px;
  border-radius: 15px 15px 0px 0px;
}

.search-box-end.directions-active {
  top: 50px;
  left: 50px;
  border-radius: 0px 0px 15px 15px;
}

@media (max-width: 768px) {
  .search-box-start.directions-active {
    top: 10px;
  }

  .search-box-end.directions-active {
    top: 40px;
  }

  .search-box-submit-button.directions-active {
    top:27px;
  }

  .search-box-clear-directions-button.directions-active {
    top: 27px;
  }
}

.current-location-button {
  position: absolute;
  left: 340px;
  top: 25px;
  z-index: 10;
  height: 20px;
  color: #659ce5;
}

.swap-start-end-button {
  position: absolute;
  left: 315px;
  top: 25px;
  z-index: 10;
  height: 20px;
}

.current-location-button:hover {
  color: #0065e9;
}

.suggestions-list {
  list-style-type: none;
  top: 65px;
  left: 50px;
  background-color: white;
  border-color: white;
  border-radius: 0px 0px 12px 12px;
  font-family: Roboto, Arial, sans-serif;
  position: absolute;
  z-index: 1000; /* Ensure it's above other elements */
  width: 320px; /* Make sure it aligns with the input */
  max-height: 40vh;
  overflow: scroll;
  padding-left: 0rem;
}

.end-suggestions-list {
  top: 87px;
}

.suggestions-list.directions-active {
  top: 50px;
}

.end-suggestions-list.directions-active {
  top: 80px;
}

@media (max-width: 768px) {
  .suggestions-list {
    top: 55px;
  }
  
  /* .end-suggestions-list {
    top: 102px;
  } */
  
  .suggestions-list.directions-active {
    top: 43px;
  }
  
  .end-suggestions-list.directions-active {
    top: 75px;
  }
}

.suggestion-location-marker {
  position: relative;
  display: flex;
  float: left;
  width: 10%;
  top: 5px;
}

.suggestion-location-text {
  padding-left: 10px;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
  font-size: 13px;
  position: relative;
  display: flex;
  float: left;
  width: 100%;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.company-logo {
  width: 60px;
  height: 60px;
  padding: 5px;
  border-radius: 12px;
}

.station-logo {
  width: 240px;
  height: 60px;
  padding: 5px;
}

.station-logo-small {
  height: 25px;
  width: 25px;
}

.station-popup-subheader-text {
  margin-top: 10px;
  margin-bottom: 10px;
}

.leaflet-popup-content-wrapper {
  width: 285px;
}

.leaflet-popup-content {
  font-family: Roboto, Arial, sans-serif;
}

.marker-button {
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
  font-size: 10px;
  font-weight: bold;
  font-family: Roboto, Arial, sans-serif;
  border-width: 0px;
  padding: 6px;
  color: black;
  background-color: #3086f76e;
}

.marker-button:active {
  background-color: #3e92ff;
}

.leaflet-interactive {
  stroke-width: 8;
}

.stripe-button-div {
  z-index: 100000;
  border-radius: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.stripe-button {
  background-color: rgba(0, 0, 255, 0);
  border-color: rgba(0, 0, 255, 0);
  border-radius: 12px;
}

.stripe-button-image {
  width: 120px;
  height: 28px;
  border-radius: 12px;
  box-shadow: -3px -3px 4px rgba(135, 135, 135, 0.314);
}

.stripe-button-image:hover {
  filter: brightness(0.7);
}

.offcanvas {
  width: 175px !important;
}

.offcanvas-body {
  display: flex;
  justify-content: center;
  background-color: #037fff;
}

.off-canvas-button {
  top: 30px;
  left: 5px;
  z-index: 1;
  position: absolute;
  padding: 0px !important;
  background-color: #ffffff00 !important;
  border-color: #ffffff00 !important;
}

@media (max-width: 768px) {
  .off-canvas-button {
    top: 15px;
  }
}

.off-canvas-button-image {
  width: 40px;
  height: 40px;
}

.off-canvas-button-icon {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0px;
}

.feedback-button-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.feedback-button {
  z-index: 10000;
  font-size: small;
  padding: 0px !important;
  color: white !important;
  font-weight: bold !important;
  background-color: #ffffff00 !important;
  border-color: #ffffff00 !important;
}

.modal-content {
  background: hsl(210, 8%, 95%) !important;
  border-radius: 20px !important;
}

.modal-body {
  padding: 25px 25px 0px 25px !important;
}

.button-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column; /* Align items in a column */
  gap: 10px; /* Space between buttons */
  align-items: top;
}

/* .leaflet-popup-content-wrapper {
  width: 100% !important;
}

.leaflet-popup-content {
  width: 260px;
  background-color: #287ae7d6;
  padding: 10px;
  border-radius: 15px;
  margin: 10px;
  color: white;
  font-size: 20px !important;
  margin: 10px !important;
} */

.list-group-item {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.list-group-item.active {
  background-color: #93bfff !important;
}

.accordion-item {
  border: 0px !important;
}

.accordion-header {
  font-size: 12px;
}

.accordion-button {
  padding: 3px !important;
  background-color: white !important;
  border: 0px !important;
  font-size: small;
  color: black !important;
  font-weight: bold !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-body {
  padding: 0px !important;
}

.current-location-arrow {
  top: 88px;
  left: 40px;
  position: absolute;
  width: 55px;
  z-index: 10;
}

@media (max-width: 768px) {
  .current-location-arrow {
    top: 60px;
    left: 0px;
  }
}

.current-location-arrow-button{
  z-index: 1000;
}

.current-location-marker{
  z-index: 1000;
  top: 420px;
  left: 400px;
  position: absolute;
  height: 20px;
  width: 20px;
}

.search-area-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-area-button {
  top: 35px;
  position: absolute;
  width: 150px;
  height: 35px;
  z-index: 10;
  background-color: white;
  border-radius: 20px;
  border: 2px solid black;
  display: none;
  color: black;
  font-size: 14px;
}

/* move the search area button down if on mobile */
@media (max-width: 768px) {
  .search-area-button {
    top: 90px;
    height: 30px;
  }

  .search-area-button.directions-active {
    top: 110px;
  }
}

.new-filters-container {
  position: absolute;
  top: 88px;
  left: 100px;
  width: calc(100vw - 100px);
  overflow-x: auto;
  z-index: 1;
  color: black;
}

@media (max-width: 768px) {
  .new-filters-container {
    top: 60px;
    left: 55px;
    width: calc(100vw - 55px);
  }

  .new-filters-container.directions-active {
    top: 80px;
    left: 55px;
    width: calc(100vw - 55px);
  }
}

.new-filters-container-inner-div {
  display: flex;
  height: 25px;
}

.new-filters-option {
  display: inline-block;
  white-space: nowrap;
  border-radius: 12px;
  margin-right: 10px;
  font-size: 14px;
  padding: 0px 10px 0px 10px;
  background-color: white;
  color: black;
  border: 2px solid black;
}

.new-filters-option.selected {
  background-color: rgb(139 186 255);
}

.new-filters-button{
  display: block;
}

.new-filters-list{
  display: none;
}

/* station info box mobile */
.station-info-box-mobile {
  position: absolute;
  bottom: 0px;
  min-height: 80px;
  max-height: 600px;
  transition: max-height 0.1s ease, height 0.1s ease;
  width: 100vw;
  background-color: white;
  z-index: 1;
  border-radius: 20px 20px 0px 0px;
  padding: 20px 40px;
  border: 2px solid black;
  border-bottom: none;
}

.station-info-box-mobile-logo-container {
  padding: 5px;
  display: flex;
  justify-content: center;
}

.station-info-box-mobile-station-logo {
  width: 85%;
  height: 60px;
  border-radius: 12px;
  user-select: none;
  pointer-events: none;
}

.station-info-box-mobile-company-logo {
  width: 55px;
  height: 55px;
  padding: 5px;
  border-radius: 12px;
  user-select: none;
  pointer-events: none;
}

/* dont display the station info box if not on mobile */
@media (min-width: 768px) {
  .station-info-box-mobile {
    display: none !important;
  }
}

.station-info-box-mobile-marker-button {
  font-size: 11px;
  font-weight: normal;
  margin-left: 10px;
  padding: 5px;
}

.station-info-box-mobile-company-logos-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80vw;
}

.station-info-box-mobile-station-name-text-container {
  line-height: 1.1;
}

.station-info-box-mobile-station-name-text {
  font-size: 12px;
  margin-left: 5px;
}

.station-info-box-mobile-drag-icon-container {
  display: flex;
  justify-content: center;
  height: 0px;
}

.station-info-box-mobile-drag-icon {
  width: 35px;
  height: 5px;
  background-color: grey;
  position: relative;
  top: -10px;
  border-radius: 12px;
}

.station-info-box-mobile-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 20;
  background-color: white;
  border: none;
}

.station-info-box-mobile-close-button-img {
  height: 20px;
  width: 20px;
}

.station-marker-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: white;
  border-radius: 12px;
  border: 1px solid black;
}

.station-marker-logo.selected {
  border: 2px solid red;
}

.station-marker-info-container {
  display: flex;
  align-items: center;
}

.station-marker-restaurant-count {
  font-size: 12px;
  color: black;
  font-weight: bold;
}

.station-marker-restaurant-icon {
  font-size: 12px;
}

.station-marker-restaurant-nib {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid black;
  background-color: transparent;
}

p{
  margin-bottom: 0rem !important;
}